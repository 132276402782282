
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import HeaderComponent from './components/header/header.component';
import NotFoundPage from './pages/not-found/not-found.page';
import HomePage from './pages/home/home.page';
import AcessoPage from './pages/acesso/acesso.page';
import ProtectedRoute from './protected-route';
import SnackComponent from './components/snack/snack.component';
import LoadingComponent from './components/loading/loading.component';
import RegulamentoPage from './pages/regulamento/regulamento.page';
import CadastroClientePage from './pages/cadastro-cliente/cadastro-cliente.page';
import CadastroVendaPage from './pages/cadastro-venda/cadastro-venda.page';
import PainelVendasPage from './pages/painel-vendas/painel-vendas.page';
import DownloadsPage from './pages/downloads/downloads.page';
import DashboardPage from './pages/dashboard/dashboard.page';

function Routes() {

    return (
        <BrowserRouter>
            <HeaderComponent />
            <Switch>
                <Route exact path="/" component={AcessoPage} />
                <ProtectedRoute
                    exact={true}
                    path={'/home'}
                    component={PainelVendasPage}
                />
                {/* <ProtectedRoute
                    exact={true}
                    path={'/cadastro-cliente'}
                    component={CadastroClientePage}
                /> */}
                <ProtectedRoute
                    exact={true}
                    path={'/cadastro-venda'}
                    component={CadastroVendaPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/painel-vendas'}
                    component={PainelVendasPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/dashboard'}
                    component={DashboardPage}
                />
                <ProtectedRoute
                    exact={true}
                    path={'/downloads'}
                    component={DownloadsPage}
                />
                <Route exact path="/regulamento" component={RegulamentoPage} />
                <Route component={NotFoundPage} />
            </Switch>

            <SnackComponent />
            <LoadingComponent />
        </BrowserRouter>
    )
}

export default Routes;