import { NavLink, useHistory } from "react-router-dom";
import './header.component.scss';
import logoPrometeon from '../../assets/logo-prometeon.svg';
import { Button } from "@mui/material";
import UsuarioModel from "../../models/usuario.model";
import React, { useEffect } from "react";
import { UsuarioEfetuouLoginService } from "../../services/usuario-efetuou-login.service";
import MenuIcon from '@mui/icons-material/Menu';
import { getAuthUser } from "../../services/auth";

function HeaderComponent() {

  const [usuarioLogado, setUsuarioLogado] = React.useState<UsuarioModel | undefined>(getAuthUser());
  const [toggleMenu, setToggleMenu] = React.useState<boolean>(false);
  let history = useHistory();

  useEffect(() => {

    UsuarioEfetuouLoginService.getMSG().subscribe((usuarioService: any) => {
      setUsuarioLogado(usuarioService);
    });
  }, []);

  const Sair = () => {
    localStorage.removeItem('user');
    setUsuarioLogado(undefined);
    history?.push({
      pathname: '/'
    })
  }

  return (
    <>
      {
        usuarioLogado &&
        <header className="flex flex-sp flex-cv">
          <img src={logoPrometeon} alt="Logo Prometeon" />

          <Button onClick={() => setToggleMenu(!toggleMenu)}>
            <MenuIcon className="menu-icon" />
          </Button>
          <nav className={"ativo-" + toggleMenu}>
            <ul className="flex flex-sp flex-cv">
              {/* <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/home" activeClassName="active">
                  <Button>Home</Button>
                </NavLink>
              </li> */}
              {/* <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/cadastro-cliente" activeClassName="active">
                  <Button>Cadastrar cliente</Button>
                </NavLink>
              </li> */}
              {/* <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/cadastro-venda" activeClassName="active">
                  <Button>Cadastrar venda</Button>
                </NavLink>
              </li> */}
              <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/painel-vendas" activeClassName="active">
                  <Button>Painel vendas</Button>
                </NavLink>
              </li>
              {
                usuarioLogado?.user_role == 'administrator' &&
                <li onClick={() => setToggleMenu(false)}>
                  <NavLink exact={true} to="/dashboard" activeClassName="active">
                    <Button>Dashboard</Button>
                  </NavLink>
                </li>
              }
              <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/downloads" activeClassName="active">
                  <Button>PDV</Button>
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <NavLink exact={true} to="/regulamento" activeClassName="active">
                  <Button>Regulamento</Button>
                </NavLink>
              </li>
              <li onClick={() => setToggleMenu(false)}>
                <Button className="sair" onClick={Sair}>Sair</Button>
              </li>
            </ul>
          </nav>
        </header>
      }
    </>
  );
}

export default HeaderComponent;
